function BasicHorizonEventRenderer() {
	var t = this;

	// exports
	t.renderEvents = renderEvents;
	t.clearEvents = clearEvents;


	// imports
	HorizonDayEventRenderer.call(t);


	function renderEvents(events, modifiedEventId) {
		// If we are breaking out by a dynamic data field
		var breakoutField = t.opt('horizonBreakoutField');
		var breakoutList;

		if (breakoutField && !t.calendar.fromRerender) {
			if (breakoutField === 'schedule') {
				breakoutList = t.opt('schedulesAll')(events);
			}
			else {
				breakoutList = t.opt('customFieldsAll')(events);
			}
			if (breakoutList) {
				t.calendar.fromRerender = true;
				t.breakoutCustomFields = breakoutList;
				t.calendar.redraw();

				return;
			}	

		}

		if (t.calendar.fromRerender) {
			t.calendar.fromRerender = null;
		}
		t.renderDayEvents(events, modifiedEventId);
	}


	function clearEvents() {
		t.getDaySegmentContainer().empty();
		t.getNubContainer().empty();
		t.getDistanceContainer().empty();
	}


	// TODO: have this class (and AgendaEventRenderer) be responsible for creating the event container div

}

;;
